// eslint-disable no-console no-unused-vars

// import audio from '@/audio/click.mp3'
// import Cursor from './libs/Cursor'
import greeting from './libs/greeting'

// import '@/images/Main-Logo.svg'
// import '@/images/jerumidicsobib.svg'
// import '@/images/Visa.svg'
// import '@/images/MasterCard.svg'

import './components/nav-menu'

/* The initial HTML document has been completely loaded and parsed */
document.addEventListener('DOMContentLoaded', () => {})

// nunjucks.render('index.html', { test: 'James' });

/**
 * The whole page has been completely loaded, including
 * all dependent resources (stylesheets and images)
 */

window.addEventListener('load', () => {
  greeting({ name: 'Veronika', email: 'veronika@me.com' })
})
